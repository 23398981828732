import { Entity, MicroMClient } from "@mcurros2/microm";
import { IconUserScan } from "@tabler/icons-react";
import { RealEstateAgentsProfileDef } from "./RealEstateAgentsProfileDef";

export const RealEstateAgentsProfileIcon = IconUserScan;
export const RealEstateAgentsProfileHelpText = '* Completing your profile data will help you get better results when people are looking for an Agent';

export class RealEstateAgentsProfile extends Entity<RealEstateAgentsProfileDef> {

    constructor(client: MicroMClient, parentKeys = {}) {
        super(client, new RealEstateAgentsProfileDef(), parentKeys);
        this.Form = import('./RealEstateAgentsProfileForm').then((module) => module.RealEstateAgentsProfileForm);
        this.Title = "Profile";
        this.HelpText = RealEstateAgentsProfileHelpText;
        this.Icon = RealEstateAgentsProfileIcon;
    }
}