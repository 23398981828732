import { Button, Group, Stack, Text, Title } from "@mantine/core";
import { CircleFilledIcon, DBStatusResult, MicroMClient, OperationStatus, useModal, useOpenForm } from "@mcurros2/microm";
import { useEffect } from "react";
import { NewsletterSubscriptions } from "../Entities/NewsletterSubscriptions/NewsletterSubscriptions";
import { IconCheck } from "@tabler/icons-react";


export interface SubscribeNewsletterProps {
    client: MicroMClient,
    openState: boolean,
    setOpenState: (open: boolean) => void
}

export function SubscribeNewsletter({ client, openState, setOpenState }: SubscribeNewsletterProps) {
    const modals = useModal();
    const openForm = useOpenForm();

    useEffect(() => {
        const open = async () => {
            const newsletter = new NewsletterSubscriptions(client);
            await openForm({
                entity: newsletter,
                initialFormMode: 'add',
                OKText: 'Subscribe',
                onModalClosed: () => {
                    setOpenState(false);
                },
                onModalSaved: async (status: OperationStatus<DBStatusResult | null>) => {
                    if (!status.error && !status.data?.Failed) {
                        await modals.open({
                            modalProps: {
                                size: 'md',
                                title: <Title order={3}>Thank you for subscribing!</Title>
                            },
                            content:
                                <Stack>
                                    <Group spacing="xs" noWrap>
                                        <CircleFilledIcon width="2rem" icon={<IconCheck size="1rem" />} backColor="green" />
                                        <Text>
                                            You have successfully subscribed to our newsletter. We will keep you updated with our progress.
                                        </Text>
                                    </Group>
                                    <Group position="right">
                                        <Button onClick={async () => await modals.close()}>Close</Button>
                                    </Group>
                                </Stack>

                        });
                    }
                }
            });
        }

        if (openState) {
            open();
        }
    }, [openState, setOpenState, client]);

    return null;
}