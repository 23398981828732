import { DefaultColumns, EntityColumn, EntityDefinition, EntityColumnFlags, CommonFlags as c } from "@mcurros2/microm";

const columns = () =>
(
    {
        c_user_id: new EntityColumn<string>({ name: 'c_user_id', type: 'char', length: 20, flags: c.PK, prompt: 'User Id' }),
        vc_person_name: new EntityColumn<string>({ name: 'vc_person_name', type: 'varchar', length: 255, flags: c.Edit, prompt: 'Name' }),
        vc_person_lastname: new EntityColumn<string>({ name: 'vc_person_lastname', type: 'varchar', length: 255, flags: c.Edit, prompt: 'Lastname' }),
        vc_person_email: new EntityColumn<string>({ name: 'vc_person_email', type: 'varchar', length: 2048, flags: c.Edit, prompt: 'Email' }),
        vc_contact_phone: new EntityColumn<string>({ name: 'vc_contact_phone', type: 'varchar', length: 255, flags: c.Edit | EntityColumnFlags.nullable, prompt: 'Phone' }),
        c_photofileprocess_id: new EntityColumn<string>({ name: 'c_photofileprocess_id', type: 'char', length: 20, flags: c.FK | EntityColumnFlags.nullable, prompt: 'Photofileprocess Id' }),
        vc_photoguid: new EntityColumn<string>({ name: 'vc_photoguid', type: 'varchar', length: 255, flags: c.Edit | EntityColumnFlags.nullable, prompt: 'Photoguid' }),
        ...DefaultColumns()
    }
)

const views = () =>
(
    {
        rsap_brwStandard: { name: 'rsap_brwStandard', keyMappings: { c_user_id: 0 } }
    }
)

export class RealEstateAgentsProfileDef extends EntityDefinition {

    columns = columns();
    views = views();

    constructor() {
        super('RealEstateAgentsProfile');
    }
}